<template>
  <descriptive-content
    :title="title"
    :layout="layout"
    :subtitle="subtitle"
    :description="description"
    :is-digital-signage="isDigitalSignage"
    :hide="layout === 'homepage' || hide">
    <div class="image">
      <img
        class="img"
        :src="image"
        @load="onLoad"
        :style="imageHeight"
        v-if="showImage"
      />
      <audio
        controls
        :autoplay="isDigitalSignage">
        <source :src="audio">
        Your browser does not support the audio element.
      </audio>
    </div>
  </descriptive-content>
</template>

<script>
  import ContentMixin from '../../mixin/content'

  export default {
    props: [
      'hide',
      'layout',
      'category',
      'adjustContentHeight',
      'isDigitalSignage',
    ],
    computed: {
      image() {
        if (this.category) {
          const media = this.category?.media?.links?.find(l => l.rel === 'poster')
          if (media) {
            return media.href
          }
        }
        return this.category?.media?.links[0]?.href
      },
      showImage() {
        return this.image
          && (this.layout !== 'homepage'
            || (this.layout === 'homepage' && !this.$helper.findAttribute(this.category, 'Media')?.inherited))
      },
      audio() {
        if (this.category) {
          const media = this.category?.media?.links?.find(l => l.rel === 'preview')
          if (media) {
            return media.href
          }
        }
        return this.category?.media?.links[0]?.href
      }
    },
    data() {
      return {
        imageHeight: {
          height: '100%'
        }
      }
    },
    // async mounted() {
    //   this.audio = new Audio(this.audio)
    //   await this.audio.play()
    // },
    // async beforeUnmount() {
    //   if (this.audio) {
    //     await this.audio.pause()
    //   }
    // },
    methods: {
      onLoad() {
        if (this.adjustContentHeight) {
          let image = document.querySelector('.img')
          let appElement = document.querySelector('#app')
          let navigation = document.querySelector('.navigation')
          if (appElement && navigation) {
            let maxHeight = appElement.clientHeight - (navigation.clientHeight + 248)
            if (image?.height >= maxHeight) {
              this.imageHeight = {
                'height': maxHeight + 'px'
              }
            }
          }
        }
      }
    },
    mixins: [
      ContentMixin
    ],
    inject: [
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
  .image {
    @apply
    flex
    w-full
    h-full
    flex-col
    items-center
    justify-center
    max-w-full
    max-h-full;
  }

  .img {
    @apply
    mx-auto
    max-w-full
    max-h-full;
  }

  audio {
    @apply
      mt-2;
  }
</style>